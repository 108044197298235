body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  color: #333;
  background-color: #f5f5f5;
  font-size: 12px;
}

.page-break {
  page-break-before: always;
  margin-top: 20px;
  /* 20px space before page breaks */
  margin-bottom: 20px;
  /* Ensure there's space below each section */
}
.acheiv {
  font-size: 1.2rem !important;
}

/* Section Styles */
.section {
  margin-bottom: 30px;
  /* Default spacing between sections */
  padding-bottom: 20px;
  /* Additional padding */
  border-bottom: 1px solid #ccc;
  /* Optional visual separator */
}

.section-content {
  margin-bottom: 10px;
  /* Space within the section content */
}

/* Print Media Queries */
@media print {
  .template-container {
    page-break-inside: avoid;
    margin-bottom: 20px;
    /* Space before page breaks */
    width: 100% !important;
  }

  .template-container > .container > * {
    page-break-inside: avoid;
    width: 100%;
  }

  .section-content {
    margin-bottom: 20px;
    /* Ensures content does not touch the page break line */
  }

  /* Adjust page-break positioning */
  .page-break {
    page-break-before: always;
    margin-top: 30px;
    /* Add a larger space before the page break */
  }
}

/* Skill Section */
.skill {
  background-color: transparent !important;
}

/* Container Styles */
.container {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin: 20px auto;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  align-items: stretch !important;
}

.template-container {
  display: block;
  height: 100%;
  min-height: inherit;
  width: 100%;
}

/* Left Panel */
.leftPanel {
  width: 35%;
  background: #2c3e50;
  color: #fff;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  overflow-wrap: anywhere;
}
.smallText {
  overflow-wrap: anywhere;
}
.item {
  margin-bottom: 20px;
}

.item h2 {
  font-family: "Julius Sans One", sans-serif;
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.item p,
.item span {
  font-size: 0.9rem;
  line-height: 1.6;
}

.contactIcon {
  margin-right: 10px;
}

.bottomLineSeparator {
  border-bottom: 1px solid #34495e;
  padding-bottom: 10px;
}

/* Right Panel */
.rightPanel {
  flex: 1;
  width: 65%;
  padding: 20px;
  background: #ecf0f1;
  box-sizing: border-box;
  page-break-before: auto;
}

.rightPanel h1 {
  font-size: 2rem;
  margin-bottom: 5px;
}

.rightPanel h3 {
  font-size: 1.2rem;
}

.rightPanel h2 {
  font-size: 1.4rem;
  margin-top: 20px;
  margin-bottom: 10px;
}

.rightPanel p,
.rightPanel ul li {
  font-size: 0.95rem;
  line-height: 1.6;
}

/* Mobile View */
@media (max-width: 768px) {
  body {
    font-size: 8px;
  }

  .container {
    width: 100%;
    margin: 0;
    box-shadow: none;
  }

  .leftPanel,
  .rightPanel {
    padding: 10px;
  }

  .item h2 {
    font-size: 1rem;
  }

  .rightPanel h1 {
    font-size: 1.8rem;
  }

  .rightPanel h3 {
    font-size: 1rem;
  }

  .rightPanel h2 {
    font-size: 1.2rem;
  }
}
