body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    color: #333;
    background-color: #f5f5f5;
    font-size: 12px;
  }
  
  .page-break {
    page-break-before: always;
    margin-top: 30px;
    /* 20px space before page breaks */
    margin-bottom: 30px;
    /* Ensure there's space below each section7 */
  }
  
  
  /* Section7 Styles */
  .section7 {
    margin-bottom: 30px;
    /* Default spacing between section7s */
    padding-bottom: 20px;
    /* Additional padding */
    border-bottom: 1px solid #ccc;
    /* Optional visual separator */
  }
  
  .section7-content {
    margin-bottom: 10px;
    /* Space within the section7 content */
  }
  
  /* Print Media Queries */

  .smallText7{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-bottom: 25px !important;
  }
  /* Skill Section7 */
  .skill7 {
    background-color: #DAD6D5  !important;
    color: black;
    display: flex;
    border: 1px solid #DAD6D5;
    border-radius: 10px;
    padding: 4px;
    align-items: center;
    text-align: center;
    padding-bottom: 4px ;
  }
  
  /* Container Styles */
  .container {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    margin: 20px auto;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    align-items: stretch !important;
  }
  
  .template-container {
    display: block;
    height: 100%;
    min-height: inherit;
    width: 100%;
  }
  .header-profile{
    display: flex;
  }
  .profile-image7 {
    height: 128px;
    width: 128px;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
}
  /* Left Panel */
  .leftPanel7 {
    border-right: 1px solid #ada3a3;
    width: 40%;
    background: #fff;
    color: #000000;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
  }
  
  .item {
    margin-bottom: 20px;
  }
  
  .item h2 {
    font-family: 'Julius Sans One', sans-serif;
    font-size: 1.2rem;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  
  .item p,
  .item span {
    font-size: 0.9rem;
    line-height: 1.6;
  }
  
  .contactIcon {
    margin-right: 10px;
  }
  
  .bottomLineSeparator {
    border-bottom: 1px solid #34495e;
    padding-bottom: 10px;
  }
  
  /* Right Panel */
  .rightPanel7  {
    flex: 1;
    width: 60%;
    padding: 20px;
    background: #fff;
    box-sizing: border-box;
  }
  
  .rightPanel7 h1 {
    font-family: 'Archivo Narrow', sans-serif;
    font-size: 2rem;
    margin-bottom: 5px;
  }
  
  .rightPanel7 h3 {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.2rem;
    color: #7f8c8d;
  }
  
  .rightPanel7 h2 {
    font-size: 1.4rem;
    margin-top: 0px !important;
    margin-bottom: 10px;
  }
  .orangebg {
    background: #22c375; /* Fallback solid background */
    background: linear-gradient(
      135deg, 
      #22c375 75%, 
      #22c375 75%, 
      rgb(218, 214, 213) 76%
    );
    padding: 40px 20px;
}
.proddesc {
  line-height: 1.5; /* Adjust line height for better readability */
}
.section7-content , .smallText7 {
  page-break-inside: avoid !important;
  break-inside: avoid !important;
  overflow: hidden !important;
}
  
  .rightPanel7 p,
  .rightPanel7 ul li {
    font-size: 0.95rem;
    line-height: 1.6;
  }
  
  @media print {
    .template-container {
      page-break-inside: avoid;
      margin-bottom: 20px;
      width: 100% !important;
    }
  
    .template-container>.container>* {
      width: 100%;
    }
  
    .section7-content {
      padding-bottom: 30px;
    }
  
    /* Adjust page-break positioning */
    .page-break {
      page-break-before: always;
      margin-top: 30px;
    }
  }
  /* Mobile View */
  @media (max-width: 768px) {
    body {
      font-size: 8px;
    }
  
    .container {
      width: 100%;
      margin: 0;
      box-shadow: none;
    }
  
    .leftPanel7,
    .rightPanel7 {
      padding: 10px;
    }
  
    .item h2 {
      font-size: 1.0rem;
    }
  
    .rightPanel7 h1 {
      font-size: 1.8rem;
    }
  
    .rightPanel7 h3 {
      font-size: 1rem;
    }
  
    .rightPanel7 h2 {
      font-size: 1.2rem;
    }
  }