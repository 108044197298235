body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  background-color: #f5f5f5;
  font-size: 12px;
}

.page-break {
  page-break-before: always;
  margin-top: 30px;
  margin-bottom: 30px;
}
.profileimg10 {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F1BC94;
}
.section8 {
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}
.profilename10 {
  padding-left: 0px;
  padding-top: 0px;
  padding: 10px;
  align-items: center;
  justify-content: left;
  display: grid;
  background-color: #FCF2E9;
}
.section8-content {
  margin-bottom: 10px;
}
.profile10 {
  font-size: 34px;
  font-weight: 600;
}
.smallText10 {
  display: grid;
  flex-wrap: wrap;
  gap: 10px;
  padding-bottom: 25px !important;
}
.smallText8 {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-bottom: 25px !important;
}
.skill7 {
  background-color: #dad6d5 !important;
  color: black;
  display: flex;
  border: 1px solid #dad6d5;
  border-radius: 10px;
  padding: 4px;
  align-items: center;
  text-align: center;
  padding-bottom: 4px;
}
.smallTexttext10 {
  margin-bottom: 0px !important;
}
.container {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin: 20px auto;
  background: transparent !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  align-items: stretch !important;
}
.headtextl {
  display: inline-flex;
  padding: 5px 5px;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: left;
  background-color: transparent;
}
.headtextr {
  display: inline-flex;
  padding: 5px 5px;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: left;
  color: #333;
  background-color: transparent;
}

.template-container8 {
  display: block;
  height: 100%;
  min-height: inherit;
  width: 100%;
  color: white;
  background-color: #333 !important;
}
.header-profile {
  display: flex;
}
.profile-image10 {
  height: 160px;
  width: 160px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid white;
}
.leftPanel10 {
  width: 45%;
  background: transparent;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  background-color: #FCF2E9;
}

.item {
  margin-bottom: 20px;
}

.item h2 {
  font-family: "Julius Sans One", sans-serif;
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.item p,
.item span {
  font-size: 0.9rem;
  line-height: 1.6;
}

.contactIcon {
  margin-right: 10px;
}

.bottomLineSeparator8 {
  border-bottom: 1px solid #fafafa !important;
  padding-bottom: 10px;
}

/* Right Panel */
.rightPanel10 {
  flex: 1;
  width: 55%;
  padding: 20px;
  background: white;
  box-sizing: border-box;
  color: #333;
}

.rightPanel10 h1 {
  font-family: "Archivo Narrow", sans-serif;
  font-size: 2rem;
  margin-bottom: 5px;
}

.rightPanel10 h3 {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.2rem;
  color: #7f8c8d;
}

.rightPanel10 h2 {
  font-size: 1.4rem;
  margin-top: 0px !important;
  margin-bottom: 10px;
}

.proddesc {
  line-height: 1.5; /* Adjust line height for better readability */
}
.section8-content,
.smallText8 {
  page-break-inside: avoid !important;
  break-inside: avoid !important;
  overflow: hidden !important;
}

.rightPanel10 p,
.rightPanel10 ul li {
  font-size: 0.95rem;
  line-height: 1.6;
}

@media print {
  .template-container {
    page-break-inside: avoid;
    margin-bottom: 20px;
    width: 100% !important;
  }

  .template-container > .container > * {
    width: 100%;
  }

  .section8-content {
    padding-bottom: 30px;
  }

  /* Adjust page-break positioning */
  .page-break {
    page-break-before: always;
    margin-top: 30px;
  }
}
/* Mobile View */
@media (max-width: 768px) {
  body {
    font-size: 8px;
  }

  .container {
    width: 100%;
    margin: 0;
    box-shadow: none;
  }

  .leftPanel8,
  .rightPanel10 {
    padding: 10px;
  }

  .item h2 {
    font-size: 1rem;
  }

  .rightPanel10 h1 {
    font-size: 1.8rem;
  }

  .rightPanel10 h3 {
    font-size: 1rem;
  }

  .rightPanel10 h2 {
    font-size: 1.2rem;
  }
}
