@tailwind base;
@tailwind components;
@tailwind utilities;

.main-box {
    max-width: 600px;
    margin: 50px auto;
    padding: 30px 50px;
    color: black;
    background-color: aquamarine;
    border-radius: 20px;
}

.alert {
    width: 400px;
    border-color: grey;
    position: absolute;
    right: 20px;
    top: 100px;
    animation: alertFrame 1s;
    background-color: rgb(255, 165, 0);
    color: white;
    z-index: 99;
}

@keyframes alertFrame {
    0% {
        right: -500px;
    }
    100% {
        right: 20px;
    }
}

#logout {
    cursor: pointer;
}

.bg-conic-gradient {
    background: conic-gradient(from 158.4deg at 50% 50%, #FF6600 0deg, rgba(255, 102, 0, 0) 360deg);

  }
.bg-angular-gradient {
    background: conic-gradient(from 90deg at 59.21% 100%, rgb(255, 102, 0) 0deg, rgba(255, 102, 0, 0) 222.66deg, rgb(255, 102, 0) 360deg);
}
  

.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

/* In your global CSS file */
.account-panel::before {
    content: " ";
    display: block;
    background-image: url("../../public/m-acc.png");
    background-size: 28rem 8rem;
    height: 4rem;
    width: 4rem;
    left: 0;
    top: -.6rem;
    position: absolute;
  }

  
  /* .btn {
    padding: 0.6rem 1.3rem;
    background-color: #fff;
    border: 2px solid #fafafa;
    font-size: 0.95rem;
    color: #1abc9c;
    line-height: 1;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    margin: 0;
    width: 100%;
  } */
  
  .btn:hover {
    background-color: transparent;
    color: #fff;
  }
  