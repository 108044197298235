.page-container{
  height: 100%;
  padding-bottom: 20px;
}

.content{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.slide-panel{
  display: flex;
  align-items: center;
  width: 44em;
  height: 25em;
  overflow-x: scroll ;
  background-color: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}

.card-image {
  position: absolute;
  width: 100%;
  height: auto;
}

.res_image_sec{
  cursor: pointer;
  height: 85%; 
  padding-top: 11px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.bottom_section{
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: space-between;
  height: 15%;
  border-radius:0 0 16px 16px ;
  background-color: white;
  border-top: 1px dashed gray;
}

.dash_in{
  height: 100%;
  transform: scale(0.27);
  text-align: left;
  transform-origin: 0px 0px;
  width: 790px;
}

.resume-visible{
  transform:scale(1) !important;
  width: 100% !important;
}

.slide-item {
  scroll-snap-align: start;
  flex: none;
  width: 212px; /* A4 width-to-height ratio for 200px height is 141.4px */
  height: 300px;
  margin: 0 10px; /* Adjust margin for spacing */
  border-radius: 0.2em;
}


.prev, .next {
  width: 2em;
  height: 2em;
  cursor: pointer;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: 0.25s;
}

.prev:hover, .next:hover {
  transform: scale(1.25);
}

.prev {
  background-image: url('../image/back.png');
}

.next {
  background-image: url('../image/next.png');
}

::-webkit-scrollbar {
  display: none;
}

.bottom_slider_btn{
  display: none;
}

/* Adjust slide-panel for smaller screens */
@media (max-width: 768px) {
  .slide-panel {
    width: 44em;
    height: 55em;
  }
  .content{
    margin-left: -3px;
  }

  .slide-item {
    scroll-snap-align: start;
    flex: none;
    width: 285px; /* A4 width-to-height ratio for 200px height is 141.4px */
    height: 403px;
    border-radius: 0.2em;
  }

  .dash_in{
    height: 100%;
    transform: scale(0.363);
    text-align: left;
    transform-origin: 0px 0px;
    width: 790px;
  }

  .prev, .next {
    width: 1em;
    height: 1em;
    cursor: pointer;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: 0.25s;
  }
}

@media (min-width: 769px) and (max-width: 1032px) {
  .slide-panel {
    width: 30em;
  }
}

@media (max-width: 426px) {
  .dash_user_data{
    flex-direction: column;
  }
  .dash_card_header{
    justify-content: center !important;
  }
  .dash_card_header button{
    display: none;
  }
  .prev{
    display: none;
  }
  .next{
    display: none;
  }

  .dash_main_card{
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .bottom_slider_btn{
    display: flex;
    justify-content: center;
    gap: 8px;
  }

}