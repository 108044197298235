/* A4 paper size */
@page {
    size: A4;
    margin: 0;
}

/* Force page break after the first page */
.resume-content {
    page-break-after: always;
}



