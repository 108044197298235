@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Home - Container 1 */
.text-gradient {
  background: linear-gradient(90deg, #1F6463 0%, #00CBC8 53%, #EE702E 75.5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-border {
  position: relative;
  display: inline-block;
  padding-bottom: 4px; /* Adjust this if needed to fit the border */
}

.gradient-border::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px; /* Same as the border width */
  background: linear-gradient(90deg, #1F6463 0%, #00CBC8 53%, #EE702E 75.5%);
  z-index: -1; /* Place it behind the text */
}

/* .home-bg-img {
  background-image: url('./image/bg-home.png');
  background-size: cover;
  background-position: center;
  transform: rotate(-10deg); 
  position: relative; 
}

.home-bg-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); 
  z-index: 1;
} */



@media print {
  body * {
    visibility: visible;
  }
  #resumePreview, #resumePreview * {
    visibility: visible;
  }
  #resumePreview {
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    font-size: 12pt; /* Adjust font size for print as needed */
  }
}



@keyframes slideRightToLeftInfinite {
  0%, 100% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(0);
  }
}


/* index.css */
ul {
  padding: revert;
  list-style-type: initial; /* Or any other list style you prefer */
}
/* index.css */
 ol {
  padding: revert;
  list-style-type: numeric; /* Or any other list style you prefer */
}

a[target="_blank"] {
  
}

/* For small screens */
@media (max-width: 768px) {
  /* Hamburger menu icon */
  .block.max-md\\:hidden {
    display: block;
  }
  /* Menu items on small screens */
  .max-md\\:flex {
    display: none;
  }
}

/* For larger screens */
@media (min-width: 769px) {
  /* Hamburger menu icon */
  .block.max-md\\:hidden {
    display: none;
  }
  /* Menu items on larger screens */
  .max-md\\:flex {
    display: flex;
  }
}

/* Add this in your global CSS file or inside a <style> tag */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-scroll {
  animation: scroll 10s linear infinite;
}
