.flex-1 {
    height: 100vh; /* Set the height to full viewport height */
    overflow-y: auto; /* Enable vertical scrolling if content overflows */
}
.bg-color-main{
    background-color: #f3f4f6;
}

.main-div{
    height: 100vh;
}


.resume_title{
    width: 93%;
}



.preview-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
    z-index: 1000; /* Ensure it's above other content */
}

.outer-modal{
    position: relative;
    top: 50%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
}

.preview-modal {
    height: 80vh;
    overflow: auto;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.preview-content {
    max-width: 800px; /* Adjust as needed */
    width: 800px;
    margin: 0 auto;
}

.close-preview-button {
    position: relative;
    margin-top: -15px;
    background-color: #ff6a06; /* Red color */
    color: white;
    right: 15px;
    border: none;
    border-radius: 50px;
    float: right;
    margin-bottom: -15px;
    cursor: pointer;
    font-size: 20px;
    height: 35px;
    width: 35px;
}

.close-preview-button:hover {
    background-color: #d32f2f; /* Darker red on hover */
}

/* .template-container{
    margin-left: -10px;
    width: fit-content;
    border:2px solid gray;
} */

.temp_in{
    height: 414px;
}
  

.bottom_nav
{
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: height .25s ease-in-out;
  background-color: #fff; /* Background color of the bottom menu */
  color: #3f3f3f; /* Text color */
  padding: 5px 0; /* Adjust padding as needed */
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

.previewBtn{
    display: flex;
    align-items: center;
    background-color: #dedede;
    padding: 4px 8px;
    cursor: pointer;
    margin-bottom: 8px;
    border-radius: 8px;
}

.previewBtn:hover{
    background-color: #9f9f9f;
}
.floating_save{
    display: none;
    padding: 5px;
    border-radius: 5px;
    right: 5px;
    bottom: 70px;
    font-size: 16px;
    position: fixed;
}

.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline{
    border-bottom: none;
    border-radius: 0px;
}
.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable){
    border: none;
}

.assist_main_box{
    position: absolute;
    z-index: 1000;
    width: 100%;
    left: 0;
    height: 300px;
    border: 2px solid; 
    border-image: linear-gradient(to right bottom, #9400ff, #7e11fb, #651af6, #471ff1, #1223eb) 1;
    border-radius: 10px;
    overflow: scroll;
    background: rgb(255, 255, 255);
    box-shadow: rgba(23, 68, 130, 0.2) 0px 0px 1px, rgba(23, 68, 130, 0.15) 0px 2px 14px;
    transition-timing-function: ease-out;
    transition-duration: 0.15s;
    opacity: 1;
}

.ai_assist_form{
    display: flex;
    justify-items: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px;
    margin: 15px 20px;
    border: none;
    border-radius: 4px;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, .1);
    cursor: text;
}

.ai_assist_form input:focus {
    outline: none;
    box-shadow: none;
  }

  .ai_assist_form select {
    border: 1px solid;
    border-radius: 5px;
    padding: 5px;
}

.highlight_bg{
    background: linear-gradient(to right bottom, #9400ff, #7e11fb, #651af6, #471ff1, #1223eb) !important;
}


.menu-button {
    background-color: transparent;
    border: none;
    padding: 10px 15px;
    font-size: 12px;
    font-weight: 400;
    color: #555;
    cursor: pointer;
    display: flex;
    border-radius: 5px;
    flex-direction: column;
    align-items: center;
  }
  
  .menu-button:hover {
    background-color: #ffede2;
    color: #ff5e59;
  }

.right_panel{
    display: block;
}

  
.template_selector{
    display: none;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 15px;
}

.slide-panels{
    width: 100% !important;
}

.bottom_menu_up{
    display: flex;
    padding: 12px;
    justify-content: stretch;
}


.download-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.download-overlay-gif {
    max-width: 200px;
    max-height: 200px;
}

.download-overlay-text {
    color: rgb(0, 0, 0,0.6);
    font-size: 16px;
    font-weight: bold;
}

.resume-visible {
    display: block !important;
    visibility: visible !important;
}


/*PROGRESS BAR*/
.score_main{
    margin: 10px 20px;
}
.scorer{
    width: 100%;
    position: relative;
}
.score_header{
    margin-bottom: 5px;
}
.progress{
    position: absolute;
    width: 100%;
    height: 6px;
    border-radius: 5px;
    background-color: rgb(210, 214, 221);
}
.progress_bar{
    position: absolute;
    /* width: 50%; */
    height: 6px;
    border-radius: 5px;
    background: #ec930c;
}
.indicator{
    color: white;
    font-size: 15px;
    padding: 3px 5px;
    margin-left: 3px;
    border-radius: 5px;
    font-weight: 600;
    background: #ec930c;
}
/*PROGRESS BAR*/





@media (max-width: 769px) {
    .template_selector{
        display: flex;
        overflow: scroll;
    }
    .bottom_menu_up{
        display: none;
    }
    .right_panel{
        visibility: block;
        /* height: 70px; */
    }
    .floating_save{
        display: flex;
    }
  }
  
@media (max-width: 425px) {
    .bottom_nav{
        display: flex;
     }
     .transformer{
        /* transform: scale(0.35); */
        transform-origin: 0px 0px;
    }
  }

@media (min-width: 425px) and (max-width: 769px) {
    .bottom_nav{
       display: flex;
    }
    .transformer{
        /* transform: scale(0.63); */
        transform-origin: 0px 0px;
    }
    
  }

@media (min-width: 800px) {
    .bottom_nav{
        display: none;
     }
}



