.tandc_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* .tac_content {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    background-color: #ffffff;
    justify-content: center;
    height: 50%;
    overflow: hidden;
  } */
  
  /* .terms-and-conditions {
    overflow-x: scroll; 
    height: 80%;
    padding: 1rem;
  } */
  
  .accept {
    background-color: #1849AB;
    border: 1px solid transparent;
    border-color: #1849AB;
    border-radius: .25rem;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    margin-top: .5rem;
    padding: .5rem;
    text-align: center;
    vertical-align: middle;
  }
  
  button[disabled] {
    opacity: 0.3;
  }
  
  .accept:focus {
    box-shadow: 0 0 0 4px rgba(21, 156, 228, 0.7);
    outline: none;
  }
  /* .tandc_overlay {
    display: flex;
    justify-self: center;
    align-self: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
   */
  .tac_content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    max-height: 80%;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    margin: 0 auto; /* Centering horizontally */
  }
  
  
  .close_button {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
  }
  