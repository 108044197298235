.alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .alert-modal {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    width: 300px;
  }
  
  .alert-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .alert-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .alert-button.confirm {
    background-color: #ED6F2D;
    color: white;
    width: 80px;
  }
  
  .alert-button.cancel {
    background-color: #1D6362;
    color: white;
    width: 80px;
  }