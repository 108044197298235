.page_containers{
    width: 100%;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: scroll;
}

.contents{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.slide-panels{
    display: flex;
    align-items: center;
    width: 32em;
    height: 100%;
    overflow-x: scroll ;
    background-color: none;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
}

.prevs, .nexts{
    width: 2em;
    height: 2em;
    cursor: pointer;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: 0.25s ;
}

.prevs:hover, .nexts:hover { transform: scale(1.25);}

.prevs{
    background-image: url('../image/back.png');
}
.nexts{
    background-image: url('../image/next.png');
}

.img-sizes{
    scroll-snap-align: start;
    width: 100%;
    border-radius: 0.2em;
}

::-webkit-scrollbar {
    display: none;
}


/* Adjust slide-panel for smaller screens */
@media (max-width: 768px) {
    .slide-panels{
        width: 13em;
    }
    .prevs, .nexts{
        width: 1em;
        height: 1em;
        cursor: pointer;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        transition: 0.25s ;
    }
}