.big-circle {
  position: absolute;
  width: 500px;
  height: 500px;
  /* margin-top: 200px; */
  border-radius: 50%;
  background: linear-gradient(90deg, rgba(29,99,98,1) 47%, rgba(0,255,149,1) 100%);
  left: 75%;
  top:25%;
  transform: translate(-40%, 38%);
}