* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* 
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  background-color: #f4f4f4;
  padding: 20px;
} */
.template-container{
  border-radius: 0%;
}

.resume-container {
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  /* padding: 20px; */
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
}

header {
  text-align: center;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.name-title h1 {
  margin-bottom: 5px;
  font-size: 36px;
  color: #333;
}

.name-title h3 {
  color: #666;
}

.contact-info p {
  color: #666;
  font-size: 14px;
}
section h2 {
  font-size: 24px;
  margin-bottom: 10px;
  border-bottom: 2px solid #ddd;
  padding-bottom: 8px;
}

.job h3 {
  font-size: 20px;
}

.job h4 {
  font-size: 16px;
  color: #888;
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
  margin-left: 0;
}

ul li {
  margin-bottom: 10px;
}

.skills ul li {
  display: inline-block;
  margin-right: 15px;
}

