:root {
    --base-font-size: 12px;
    --heading-font-size: 14px;
    --gaint-font-size: 18px;
}

.template-container {
    position: relative;
    background-color: white;
    padding: 2%;
    border-radius: 1%;
    box-shadow: 0 1% 3% rgba(0, 0, 0, 0.1);
    min-height: 980px;
    max-width: 100%;
    box-sizing: border-box;
    transform-origin: top left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.text-center {
    text-align: center;
}

.profile-container {
    margin-bottom: 2%;
}

.profile-image {
    width: 20%;
    height: 20%;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
}

.profile-name {
    font-size: var(--gaint-font-size);
    font-weight: bold;
}

.watermark {
    position: absolute;
    z-index: 0;
    display: flex;
    /* height: 100%;
    width: 100%; */
    align-items: center;
    justify-content: center;
    transform: rotate(-45deg); /* scale(0.3); Adjust scale as needed */
    font-size: 70px;
    font-weight: bold;
    color: #1F2937;
    opacity: 0.1;
    left: 30%;
    top: 30%;
}

.section {
    margin: 2% 0;
}

.section-title {
    font-size: var(--heading-font-size) !important;
    font-weight: bold !important;
    padding: 10px 0 10px 0 ;
    width: 100% !important;
    text-align: center !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.section-title,
.section-content,section {
  page-break-inside: avoid; 
  break-inside: avoid; 
}


.section-content {
    margin-bottom: 2%;
}

.bold-text {
    font-weight: bold;
}

p {
    font-size: var(--base-font-size);
}
