body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  color: #333;
  background-color: #f5f5f5;
  font-size: 12px;
}

.page-break {
  page-break-before: always;
  margin-top: 20px;
  /* 20px space before page breaks */
  margin-bottom: 20px;
  /* Ensure there's space below each section */
}
.acheiv {
  font-size: 1.2rem !important;
}

/* Section Styles */
.section {
  margin-bottom: 30px;
  /* Default spacing between sections */
  padding-bottom: 20px;
  /* Additional padding */
  border-bottom: 1px solid #ccc;
  /* Optional visual separator */
}

.section-content {
  margin-bottom: 10px;
  /* Space within the section content */
}
.profileimg12 {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}
.profile-image12 {
  height: 160px;
  width: 160px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid white;
  border-radius: 100%;
}

/* Print Media Queries */
@media print {
  .template-container {
    page-break-inside: avoid;
    margin-bottom: 20px;
    /* Space before page breaks */
    width: 100% !important;
  }

  .template-container > .container > * {
    page-break-inside: avoid;
    width: 100%;
  }

  .section-content {
    margin-bottom: 20px;
    /* Ensures content does not touch the page break line */
  }

  /* Adjust page-break positioning */
  .page-break {
    page-break-before: always;
    margin-top: 30px;
    /* Add a larger space before the page break */
  }
}

/* Skill Section */
.skill {
  background-color: transparent !important;
}

/* Container Styles */
.container {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin: 20px auto;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  align-items: stretch !important;
}

.template-container {
  display: block;
  height: 100%;
  min-height: inherit;
  width: 100%;
}
.pad{
  padding: 5px 20px;
}

/* Left Panel */
.leftPanel12 {
  width: 35%;
  background: #1d6362;
  color: #fff;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  overflow-wrap: anywhere;
}
.smallText {
  overflow-wrap: anywhere;
  
}
.item {
  margin-bottom: 20px;
}

.item h2 {
  font-family: "Julius Sans One", sans-serif;
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.item p,
.item span {
  font-size: 0.9rem;
  line-height: 1.6;
}

.contactIcon {
  margin-right: 10px;
}

.bottomLineSeparator {
  border-bottom: 1px solid #34495e;
  padding-bottom: 10px;
}
.rightPanelfullname{
  min-height: 100px;
  align-items: center;
  display: flex;
  padding: 20px;
  /* justify-content: center; */
  background: #ed6f2d;
  color: white;
}

/* Right Panel */
.rightPanel12 {
  flex: 1;
  width: 65%;
  padding: 0px;
  background: #fff;
  box-sizing: border-box;
  page-break-before: auto;
}
.downright{
  padding: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  background: #ed6f2d;
  color: white;
}

.rightPanel12 h1 {
  font-family: "Archivo Narrow", sans-serif;
  font-size: 2rem;
}

.rightPanel12 h3 {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.2rem;
  color: #7f8c8d;
}

.rightPanel12 h2 {
  font-size: 1.4rem;
}

.rightPanel12 p,
.rightPanel12 ul li {
  font-size: 13px;
  line-height: 1.4;
}

/* Mobile View */
@media (max-width: 768px) {
  body {
    font-size: 8px;
  }

  .container {
    width: 100%;
    margin: 0;
    box-shadow: none;
  }

  .leftPanel,
  .rightPanel12 {
    padding: 10px;
  }

  .item h2 {
    font-size: 1rem;
  }

  .rightPanel12 h1 {
    font-size: 1.8rem;
  }

  .rightPanel12 h3 {
    font-size: 1rem;
  }

  .rightPanel12 h2 {
    font-size: 1.2rem;
  }
}
