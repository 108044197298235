.profcont {
  display: flex;
}
.profname {
  background-color: rgb(61, 61, 61);
  color: rgb(255, 255, 255);
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.profimage {
  background-color: #1a7080;
  color: rgb(255, 251, 251);
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  padding: 20px;
}
.profile9 {
  /* color: white; */
  font-size: 32px;
}
.headerdesc,
.downtext {
  display: flex;
}
.section-title9 {
  font-size: 24px;
}
.lefttext {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  padding: 20px;
}
.skill9 {
  padding: 4px 30px;
  align-items: center;
  display: grid;
}
.skillsalign {
  display: grid;
}
.exper {
  padding: 10px;
}
.profile-image9 {
  height: 150px;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
}
.skillstext {
  color: #1a7080;
  font-size: 12px;
  font-weight: 400;
}
.conta {
  justify-content: center;
  align-items: center;
  display: grid;
  height: 100%;
}
/* .awar {
  page-break-inside: avoid;
  page-break-before: always;
} */
.righttext {
  background-color: #e4e4e4;
  color: rgb(81, 81, 81);
  width: 100%;
  justify-content: center;
}
.edu9 {
  padding: 8px 8px;
}
.righthead {
  background-color: rgb(61, 61, 61);
  color: rgb(255, 255, 255);
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.lefthead {
  background-color: #1a7080;
  color: rgb(255, 251, 251);
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
